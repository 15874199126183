<template>
  <div>

    <van-nav-bar  :title="$t('recharge.recharge')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      </van-nav-bar>
      <div class=" flex-xy-center" v-if="!url">
        <div  style="width: 100%;">
          <div v-for="(item,index) in $store.getters.getBaseInfo.pay_banklist||[]" :key="index" class="payBtn mg-y-30 pd-xy-20 reset bg-linear-primary" @click="submit(item.name)">
            {{item.name}}
          </div>

        </div>

      </div>
      <iframe v-else class="frame"   :src="url"></iframe>
  </div>
</template>

<script>
export default {
	data() {
    return {
      btnLogin: false,
      pay_way: "Mexicopay",
      money: 0,
      url:''
		};
  },
  methods: {
    back() {
      if (this.url) {

        this.$router.replace({path:'/Mine'})
      } else {
        window.history.back();
      }
    },
    submit (type)  {
      if (this.btnLogin) return
          this.btnLogin = true

          this.$http({
            method: "post",
            data: {
              pay_way: this.pay_way,
              money: this.$route.query.money,
              bank_type:type
            },
            url: "doRecharge",
          }).then((res) => {
            if (res.code === 200) {
              // window.location.href = res.data.pay_url;
              this.url = res.data.pay_url
              // this.$router.go(-1)
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
            setTimeout(() => {
              this.btnLogin = false
            },500)
          });


    }
  }
};
</script>

<style scoped>

.changePssword-cell-name{
    font-size: 30rpx;
    font-weight: bold;
  margin-bottom: 20rpx;
  text-align: center;
}
.changePssword-cell{
  margin: 80rpx 0;
}
.payBtn{
  text-align: center;
  width: 85%;
  margin:0 30px;
  margin:60px auto;
  padding: 20px;

  color: #fff !important;
  background: linear-gradient(90deg, #775fd9, #c24491);
}
.inp-bg{
  text-align: center !important;
}
.frame{
  width: 100vw;
  height: 90vh;
}
</style>
