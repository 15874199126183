<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('concubine.concubine')" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab :title="$t('concubine.city')">
					<div class="card">{{$t("concubine.city_tip")}}</div>
					<div class="address">
						<van-cell-group v-for="(val, key) in addlist" :key="key">
						<van-cell @click="addgo(val)">{{val.name}}</van-cell>
						</van-cell-group>
					</div>
				</van-tab>
				<van-tab :title="$t('concubine.price')">
					<div class="card">{{$t("concubine.city_tip")}}</div>
					<div class="rig-box">
						<p class="rig-title">{{$t("concubine.pri_resource")}}</p>
						<p class="rig-content">{{$t("concubine.pri_obj")}}</p>
						<p class="rig-title">{{$t("concubine.pri_service")}}</p>
						<p class="rig-content">{{$t("concubine.pric_service_one")}}</p>
						<p class="rig-content">{{$t("concubine.pric_service_two")}}</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: []
		};
	},
	methods: {
		addgo(data) {
			// if (!localStorage.getItem('token')) {
			// 	this.$router.push({ path: '/Login' });
			// } else {
			// }
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #775fd9, #c24491);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
// /deep/ .van-col{
	// padding: 30px 0px;
// }
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #775fd9;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}

/deep/ .van-cell{
	padding: 30px 22px;
	font-size: 30px;
	line-height:30px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 94%;
	margin: 0 auto;
}
</style>
