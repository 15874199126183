<template>
  <div class="home-container">
    <!-- <div class="header linear-bg" style="height: auto;" v-if="this.$store.getters.getBaseInfo.ico">
      <img class="" style="height: 50px;padding: 0 15px;display: block;" :src="this.$store.getters.getBaseInfo.ico" />
      <div style="flex: 1;"></div>
      <van-icon name="service-o" size="24" color="#fff" style="margin-right: 10px;" v-if="iskefu == '1'"
        @click="toKefu" />
    </div> -->
    <div class="home-top-bar">
      <div class="login-left-div"><span @click="$router.push({ path: '/Login' })" v-if="!token" class="login-span">{{ $t("auth.login") }}</span></div>
      <div class="logo-div"><img class="logo-img" :src="this.$store.getters.getBaseInfo.ico"></div>
      <div class="login-between-div"><span @click="$router.push('Register')" v-if="!token">{{ $t("auth.register") }}</span></div>
    </div>

    <div class="mask" v-if="isLoading && video_login == 1 && !token">
      <van-loading type="spinner" size="80px" />
    </div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div class="notice-bar">
        <van-notice-bar class="notice-swipe" left-icon="bullhorn-o" background="#ffffff" color="#775fd9"
          :text="notice" />
        <div class="linear-gradient"></div>
      </div> -->
      
      <div class="hot-game">
        <!-- <div class="hot-title-div">
          <div>
            <span>{{$t('index.task')}}</span></div>
          <div @click="gotoMenu('/Game')">
            <span>{{$t('index.more')}}</span>
            <van-icon name="arrow" color="#c24491" />
          </div>
        </div> -->
        
        <div class="hot-title-div">
          <span>{{$t('index.task')}}</span>
          <!-- <div>{{$t('xuanfei.Experience')}}</div> -->
        </div>
        <div class="hot-items-div">
          <van-grid :column-num="4">
          <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
            <van-image class="game_item_img" style="width: 50px;height: 50px;" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
            <span>{{v.name}}</span>
            <!-- <span>{{v.desc}}</span> -->
          </van-grid-item>
        </van-grid>
        </div>
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <span>{{$t('xuanfei.Area')}}</span>
          <div>{{$t('xuanfei.Experience')}}</div>
        </div>
        <div class="hot-items-div">
          <van-grid :border=false :column-num="3" :icon-size="20" square>
            <van-grid-item v-for="(v, key) in movielist_1.slice(0,6)" :key="key" @click="toPlayVideo(v.id)">
              <div style="position: relative;width: 100%;height: 100%;overflow: hidden;">
                <span class="absolute-span"><i>{{$t('xuanfei.Airborne')}}</i></span>
                <van-image class="game_item_img" fit="cover"
                  :src="v.img_url">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      


      <div class="hot-game">
        <div class="hot-title-div">
          <span>{{$t('index.recmonmand')}}</span>
        </div>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <van-list class="hot-xuanfei" v-model="loading" :finished="finished" :offset="50" :immediate-check="false"
          :finished-text="$t('video.no_more')" @load="onLoad" :loading-text="$t('refresh.loading')">
          <div class="hot-items-div" v-if="movielist_1.length > 0">
            <ul>
              <li v-for="(v, key) in movielist_1" :key="key" @click="toPlayVideo(v.id)"><img
                  :src="v.img_url">
                <ul>
                  <li>
                    <div>{{ $t('xuanfei.Authentication') }}</div>
                    <div>{{ $t('xuanfei.VideoAuthentication') }}</div>
                    <div>{{v.xuanfei_name}}</div>
                  </li>
                  <li>
                    <div v-for="(li,indexs) in v.service_data" :key="indexs">{{li}}</div>
                  </li>
                  <li><span>{{$t('xuanfei.ChargingRange')}}:</span>
                    <span class="movie-list-money">{{ v.price }}</span>
                    
                    <!-- <div><span></span><span></span><span></span><span></span><span></span></div> -->
                  </li>
                  <li><span>{{$t('xuanfei.height')}}:{{v.height}} {{$t('xuanfei.round1')}}: {{v.one}} {{$t('xuanfei.serveCity')}}:{{v.address}}</span></li>
                  <li>{{$t('xuanfei.DateHer')}} </li>
                </ul>
              </li>
            </ul>
          </div>
        </van-list>

      </van-pull-refresh>
    </div>
    <van-popup v-model="pop_content" round closeable close-icon="close"
      style="width:70%;height: 50%;font-size: 24px;color: #000;">
      <div class="pop_content-content" v-html="pop_content_txt"></div>


    </van-popup>


    <img class="luckDraw" v-if="isluckdraw == '1'" @click="toLuckDraw" src="@/assets/luckdraw.png" alt="">
    <img class="kefu" v-if="iskefu == '1'" src="@/assets/kefu.png" @click="toKefu" alt="" srcset="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [],
      loading: false,
      finished: false,
      isLoading: true,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        autoplay: true,
      },
      video_login: this.$store.getters.getBaseInfo.video_login || 1,
      token: localStorage.getItem('token'),
      isluckdraw: null,
      pop_content_txt: '',
      pop_content: false,
      kefu: '',
      iskefu: 0,

      coordinate: {
        client: {},
        elePosition: {}
      },
      page: 1

    };
  },
  methods: {

    getxuanfeilist() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: { id: this.$route.query.id }
      }).then(res => {
        this.datalist = res.data;
      });
    },
    toKefu() {

      window.location.href = this.kefu
    },
    touchStartHandle(refName, e) {
      let element = e.targetTouches[0]
      // 记录点击的坐标
      this.coordinate.client = {
        x: element.clientX,
        y: element.clientY
      }
      // 记录需要移动的元素坐标
      this.coordinate.elePosition.left = this.$refs[refName].offsetLeft
      this.coordinate.elePosition.top = this.$refs[refName].offsetTop
    },
    drag(refName, e) {
      let element = e.targetTouches[0]
      // 根据初始client位置计算移动距离（元素移动位置+光标移动后的位置-光标点击时的初始位置）
      let x = this.coordinate.elePosition.left + (element.clientX - this.coordinate.client.x)
      let y = this.coordinate.elePosition.top + (element.clientY - this.coordinate.client.y)
      // 限制可移动距离，不超出可视区域
      x = x <= 0 ? 0 : x >= innerWidth - this.$refs[refName].offsetWidth ? innerWidth - this.$refs[refName].offsetWidth : x
      // 50是头部的高度
      y = y <= 50 ? 50 : x >= innerHeight - this.$refs[refName].offsetHeight ? innerHeight - this.$refs[refName].offsetHeight : y
      // 移动当前元素
      this.$refs[refName].style.left = x + 'px'
      this.$refs[refName].style.top = y + 'px'
    },
    toLuckDraw() {
      this.$router.push({ path: '/luckDraw' })
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
        }
      }

    },
    // toPlayVideo(id) {

    //   if(!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1){
    //     this.$router.push({path:'/Login'})
    //   } else {
    //     if (id) {
    //      this.$router.push({path:'/PlayVideo?id='+id})
    //     }
    //   }
    // },

    toPlayVideo(id) {

      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/nHomeDetail?id=' + id })
        }
      }
    },
    // onRefresh() {
    //   setTimeout(() => {
    //     this.getBasicConfig();
    //     this.isLoading = false;
    //     this.$toast(this.$t('reservation.refresh'));
    //   }, 500);
    // },

    onRefresh() {
      setTimeout(() => {
        this.page = 1
        // this.movielist_1 = []
        this.getNhomeList();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    onLoad() {
      this.loading = true;
      this.getNhomeList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.movielist_1 = [];
          this.refreshing = false;
        }
        // this.loading = false;
        if (this.movielist_1.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        // this.getMovieList_1(this.basicData);//获取首页视频1

        this.$nextTick(() => {
          this.lazyLoadImg()
        })
      })

    },
    getNotice(data) {
      this.notice = data.notice || '';
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1
    },
    getBanner(data) {
      this.banners = data.banners;
    },
    query(selector) {
      return Array.from(document.querySelectorAll(selector));
    },
    lazyLoadImg() {
      if (this.movielist_1.length > 0) {
        this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
      }
    },

    getNhomeList() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: { page: this.page },
      }).then(res => {
        console.log(res.data, 999)
        this.loading = false;
        if (this.page == 1) {
          this.movielist_1 = res.data;
        } else {

          this.movielist_1 = this.movielist_1.concat(res.data);
        }
        if (res.data.length == 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.count = res.data.count;
        this.page++;
      })

    }
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", () => {
      // 这里做一个 节流 操作
      that.lazyLoadImg()
    });
  },
  created() {
    this.$http({
      method: 'get',
      url: 'base_info'
    }).then(res => {
      if (res.code == 200) {

        if (!localStorage.getItem('token') && res.data.video_login == 1) {
          return this.$router.push({ path: '/Login' })
        }
        this.getNhomeList()
        console.log(res)
        this.isLoading = false
        this.isluckdraw = res.data.isluckdraw
        this.pop_content_txt = res.data.pop_content
        this.kefu = res.data.kefu
        this.iskefu = res.data.iskefu
        if (res.data.ispop == 1) {
          this.pop_content = true
        }


        this.$store.commit('setBaseInfoValue', res.data);
        this.getBasicConfig();
      }
    })
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;


.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
  height: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 100px;
  display: flex;
  align-items: center;
}

.hot-title-div>div:first-child {
  width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #c24491;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #c24491;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #c24491;
}

.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #775fd9;
  border-radius: 1px;
  margin-right: 5px;
}

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 24px;
  color: #c24491;
}



.hot-items-div {
  // padding: 12px 0;
  padding: 0 10px;
}

::v-deep .hot-items-div .van-grid-item__content {
  padding: 10px;
}

.hot-items-div .game_item_img {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.luckDraw {
  position: fixed;
  bottom: 160px;
  right: 40px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  z-index: 999;
}

.kefu {
  position: fixed;
  bottom: 300px;
  right: 60px;
  width: 80px;
  height: 80px;
  z-index: 999;
  // background: #fff;border-radius: 50px;
}

/deep/.van-popup__close-icon {
  font-size: 50px;
}

.pop_content-content {
  font-size: 30px;
  color: #1c1e23;
  padding: 30px;
  margin-top: 40px;
}





.movie-list-n-img {
  width: 330px;
  height: 330px;
  position: relative;
}

.bd-info .info-model .model-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: #8f65fe;
  border-radius: 0.5rem;
  margin: 0.625rem;
  padding: 0.625rem;
}

.bd-info .info-model .model-item .item-pic {
  position: relative;
  width: 7.5rem;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 0.625rem;
}

.bd-info .info-model .model-item .item-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: .75rem;
}

.bd-info .info-model .model-item .item-pic img {
  width: 7.5rem;
  height: 11.25rem;
  border: 0;
  vertical-align: middle;
}

.bd-info .info-model .model-item .item-info .name {
  margin-bottom: 0.625rem;
  margin-top: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag span {
  font-size: .75rem;
  color: #fff;
  display: inline-block;
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  background-color: #ea92cf;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .info {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .remark {
  // height: 2.625rem;
  line-height: 1.6;
  overflow: hidden;
  margin-bottom: 0.75rem;

  display: -webkit-box;

  overflow: hidden;

  white-space: normal !important;

  text-overflow: ellipsis;

  word-wrap: break-word;

  -webkit-line-clamp: 3;

  -webkit-box-orient: vertical
}

.bd-info .info-model .model-item .item-info .call .van-button {
  width: 100%;
  height: 2.125rem;
  border-radius: 6.1875rem !important;
}

.bd-info .info-model .model-item .item-info .remark span {
  color: #ddd2fb;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .name span {
  display: inline-block;
  color: #fff;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .info span {
  color: #ddd2fb;
  font-size: .875rem;
}

.van-button--info,
.van-button--primary {
  border: none !important;
  background: -webkit-gradient(linear, left top, right top, from(#a082f0), to(#ea92cf)) !important;
  background: linear-gradient(90deg, #a082f0, #ea92cf) !important;
  border-radius: 5px !important;
}





.movie-list-notice {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-addr {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 28px;
}

.movie-list-ico-addr {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

@keyframes rainbow_animation {

  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

.movie-list-money {
  background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  animation: rainbow_animation 10s ease-in-out infinite;
  background-size: 400% 100%;
  font-weight: bold;
  position: relative;
  margin-right: 10px;
}

.movie-list-ico-money {
  width: 30px;
  display: inline-block;
  margin-right: 10px;

}



.home-top-bar {
  width: 100%;
  padding: 0 30px !important;
  justify-content: space-between;
  background: url(../../assets/topbar_bg.png);
  background-size: cover;
  background-position: 50%
}

.home-top-bar .logo-div,
.home-top-bar {
  display: flex;
  align-items: center
}

.home-top-bar .logo-div img {
  height: 100px;
  -o-object-fit: contain;
  object-fit: contain
}

.home-top-bar .login-between-div,
.home-top-bar .login-div,
.home-top-bar .login-left-div {
  font-size: 28px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.home-top-bar .login-between-div .login-span,
.home-top-bar .login-div .login-span,
.home-top-bar .login-left-div .login-span {
  margin-right: 40px
}

.home-top-bar .login-left-div i {
  opacity: 0
}

.home-top-bar .server-msg-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px
}

.home-top-bar i {
  color: #fff
}




.hot-game .hot-items-div {
  // width: 2.93333rem;
  // height: 2.93333rem;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  overflow: hidden;
  // margin-bottom: 20px;
  position: relative;
}

.hot-game .hot-items-div img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover
}

.hot-game .hot-items-div .absolute-span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  width: 192px;
  height: 192px;
  transform: rotate(45deg);
  top: -120px;
  right: -120px;
}

.hot-game .hot-items-div .absolute-span i {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 10px);
  transform: translate(-50%, 0);
  font-size: 16px;
  font-weight: 600
}



.hot-game {
  width: 100%;
  background-color: #fff
}

.hot-game>div {}

.hot-game .hot-title-div {
  height: 80px;
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
  margin: 0 auto
}

.hot-game .hot-title-div>span {
  font-size: 32px
}

.hot-game .hot-title-div>div {
  margin-left: 20px;
  border-radius: 20px 0 20px 0;
  background: #3f3a5b;
  color: #ebcaaf;
  padding: 4px 10px;
  font-size: 22px
}

.hot-game .hot-title-div .hotganme-img {
  height: .64rem;
  margin-right: 6px;
  margin-top: -.10667rem
}





.hot-xuanfei {
  width: 100%;
  background-color: #fff
}

.hot-xuanfei>div {
  width: calc(100% - 50px);
  margin: 0 auto
}

.hot-xuanfei .hot-title-div {
  height: 1.06667rem;
  display: flex;
  align-items: center
}

.hot-xuanfei .hot-title-div>span {
  font-size: .42667rem
}

.hot-xuanfei .hot-title-div .hotganme-img {
  height: .64rem;
  margin-right: 6px;
  margin-top: -.10667rem
}

.hot-xuanfei .hot-title-div>div:first-child {
  width: 5.33333rem;
  display: flex;
  align-items: center
}

.hot-xuanfei .hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: .37333rem;
  font-weight: 700;
  color: #000
}

.hot-xuanfei .hot-title-div .no-hotgameImg-div span:before {
  content: "";
  display: block;
  width: 8px;
  height: .37333rem;
  background-color: #8e4ff1;
  border-radius: 6px;
  margin-right: 8px
}

.hot-xuanfei .hot-title-div>div:nth-child(2) span {
  font-size: .34667rem;
  color: #ed37ae
}

.hot-xuanfei .hot-title-div>div:nth-child(2) .van-icon {
  color: #ed37ae
}

.hot-xuanfei .hot-items-div {
  margin-bottom: 20px;
  display: flex
}

.hot-xuanfei .hot-items-div>ul {
  flex: 1;
  display: flex;
  flex-direction: column
}

.hot-xuanfei .hot-items-div>ul>li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px
}

.hot-xuanfei .hot-items-div>ul>li>img {
  width: 150px;
  height: 150px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 20px
}

.hot-xuanfei .hot-items-div>ul>li>ul {
  flex: 1
}

.hot-xuanfei .hot-items-div>ul>li>ul>li {
  display: flex;
  align-items: center
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:first-child {
  height: 50px;
  margin-bottom: 6px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:first-child>div:first-child,
.hot-xuanfei .hot-items-div>ul>li>ul>li:first-child>div:nth-child(2) {
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  padding: 0 6px;
  font-size: 20px;
  border-radius: 6px 0 6px 0;
  margin-right: 6px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:first-child>div:nth-child(3) {
  font-size: 24px;
  margin-left: 20px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(2) {
  flex-wrap: wrap
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(2)>div {
  height: 50px;
  background-color: #fc2372;
  color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-right: 6px;
  margin-bottom: 12px;
  font-size: 24px;
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(2)>div:nth-child(2n) {
  background-color: #ff8b24
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(2)>div:nth-child(3n) {
  background-color: #009ff9
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(3) {
  margin-bottom: 6px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(3)>span {
  font-size: 24px;
  margin-right: 20px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(3)>div {
  display: flex
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(3)>div span {
  display: block;
  width: 24px;
  height: 24px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVRIie3WMUhVURzH8c97WQg6VJIICeLUENIgDa+GmnSQCCKIHINqCkRoKWiwRVtcogjMzcVJHWrt4RBOWWu4iLW0NIRD8cjh3IeHy70+37vvbv7gDPd//ud+/3/O+f/Pqfz5eE8Heoo1fG93YbUD2CXM4XUHa9sGVvAOvZjA3bKBD3Aj+l5Ef1nAQbxK2YbxoizgIs5n2GdwudvASUznzJ0WDlClG8AqxvCmhd9NPMKpVsBKqg77cBXXUcM1nGv1k0i/sYlPqGMbjdihB7eTCGsYT2yd6ixuJSMOoJ4Esd2DAWHjy1AcwC/UqljGQknApvYT6E7z0DzHRkmwBu5ji8NT2jR+KwH4RJRMXBb7mMLPLsLm8TY2pOtwD3cSeFGt4FnamFX4W0IRF9Gm0Oj/HwcIXwoCP+Bv1kQecKggMKvJHwm8UBCY2w5PMszSrnAZH9Uk2gamM2xgXWgMo5jFFeGWWcW/lP9AHjDvKmpmuIslvJfdgerJuIjHeJisbXsPPzvM5mUOLNYP4TE1IjxFvuJMluMB65BGG6uhkFoAAAAASUVORK5CYII=) no-repeat 50%/100%
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(4) {
  margin-bottom: 12px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(4) span {
  color: #999;
  font-size: 24px
}

.hot-xuanfei .hot-items-div>ul>li>ul>li:nth-child(5) {
  background: linear-gradient(90deg, #df35ad, #4f1db5);
  color: #fff;
  border-radius: 10px;
  padding: 12px 0;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
